import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import CameraCaptureMobile from './components/CameraCapture';
import CameraCaptureEmbed from './components/CameraCaptureEmbed';
import Thanks from './components/Thanks';
import 'rsuite/dist/rsuite.min.css';
import './custom-theme.less';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/embed" element={<CameraCaptureEmbed />} />
        <Route path="/mobile" element={<CameraCaptureMobile />} />
        <Route path="/thanks" element={<Thanks />} />
      </Routes>
    </Router>
  );
}

export default App;