import { useRef, useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import Webcam from 'react-webcam';
import io from 'socket.io-client';
import { Button, Loader, Drawer } from 'rsuite';
import CameraIcon from './CameraIcon';
import Pica from 'pica';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import { useNavigate } from 'react-router-dom';

const CameraCaptureMobile = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const session = useRef(searchParams.get("session") || crypto.randomUUID());
    const attachTo = useRef(searchParams.get("attachto") || null);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const videoConstraints = isMobile ? { facingMode: { exact: "environment" } } : { facingMode: "user" };
    const webcamRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [cameraAvailable, setCameraAvailable] = useState(false);
    const [cameraBlocked, setCameraBlocked] = useState(false);
    const [openWithHeader, setOpenWithHeader] = useState(false);
    const [loading, setLoading] = useState(true);
    const [processingImage, setProcessingImage] = useState(false);
    const [socketIo, setSocketIo] = useState(null);
    const [sendding, setSendding] = useState(false);
    //const [imageBlob, setImageBlob] = useState(null);

    const capture = () => {
        setProcessingImage(true);
        const imageSrc = webcamRef.current.getScreenshot();
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => {
            // Calculate the target height to maintain a 16:9 aspect ratio
            const targetAspectRatio = 16 / 9;
            let targetHeight = img.width / targetAspectRatio;
            let targetWidth = img.width;

            // Check if the current height is larger than the target height
            if (img.height < targetHeight) {
                // If the current height is too small, adjust the width instead
                targetHeight = img.height;
                targetWidth = img.height * targetAspectRatio;
            }

            // Calculate the position to start cropping on the x and y axis
            const startX = (img.width - targetWidth) / 2;
            const startY = (img.height - targetHeight) / 2;

            // Create an off-screen canvas for the resized image
            const canvas = document.createElement('canvas');
            canvas.width = targetWidth;
            canvas.height = targetHeight;
            const ctx = canvas.getContext('2d');

            // Draw the image on the canvas, cropped and resized to the new dimensions
            ctx.drawImage(img, startX, startY, targetWidth, targetHeight, 0, 0, targetWidth, targetHeight);

            const overlayText = "ใช้สำหรับสมัครงานกับบริษัท เอสเอสพี 2655 จำกัด เท่านั้น";
            ctx.font = "300 18px 'Noto Sans Thai'"; // Adjust font size and type as needed
            ctx.fillStyle = "rgba(255, 0, 0, 0.5)"; // Adjust text color and alpha for transparency
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";

            // Rotate the context and add the text
            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            ctx.save(); // Save the current state of the canvas so we can revert it after drawing the text
            ctx.translate(centerX, centerY);
            ctx.rotate(-Math.PI / 4); // Rotate by 45 degrees (Math.PI / 4 radians)
            ctx.fillText(overlayText, 0, 0); // The text is drawn at the origin since the context is translated and rotated
            ctx.restore(); // Restore the canvas state to not affect the subsequent resizing

            // Use Pica to resize the cropped image to the final desired size
            const pica = Pica();
            const resizedCanvas = document.createElement('canvas');
            const desiredWidth = 1920; // Set the desired width for 16:9 aspect ratio
            const desiredHeight = Math.round(desiredWidth / targetAspectRatio); // Calculate the desired height

            resizedCanvas.width = desiredWidth;
            resizedCanvas.height = desiredHeight;

            // Resize the cropped image
            pica.resize(canvas, resizedCanvas)
                .then(result => pica.toBlob(result, 'image/jpeg', 1)) // Use highest quality for JPEG
                .then(blob => {
                    //setImageBlob(blob);
                    // Create a URL for the resized image blob
                    const resizedImageSrc = URL.createObjectURL(blob);
                    setImageSrc(resizedImageSrc); // Update state with the resized image URL
                    handleOpen();
                    setProcessingImage(false);
                })
                .catch(error => {
                    console.error('Pica resizing error:', error);
                });
        };
    }

    useEffect(() => {
        const updateCameraStatus = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });

                setCameraAvailable(true);
                setCameraBlocked(false);

                stream.getTracks().forEach(track => track.stop());
            } catch (error) {
                console.error(error.message);
                if (error.message.includes('NotFoundError')) {
                    setCameraAvailable(false);
                    setCameraBlocked(false);
                } else if (error.message.includes('Permission denied')) {
                    setCameraAvailable(true);
                    setCameraBlocked(true);
                }
            } finally {
                setLoading(false);
            }
        };

        updateCameraStatus();

        navigator.mediaDevices.ondevicechange = updateCameraStatus;
    }, []);


    const submitPhoto = () => {
        setSendding(true);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = imageSrc;
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const base64data = canvas.toDataURL('image/jpeg');
            socketIo.emit('photoTaken', { session: session.current, attachTo: attachTo.current, imageSrc: base64data });
            socketIo.emit('message', { session: session.current, message: 'photoTaken' });
            setSendding(false);
            navigate('/thanks');
        }
    };

    const handleOpen = () => {
        setOpenWithHeader(true);
    }

    const handleClose = () => {
        setOpenWithHeader(false);
    }

    useEffect(() => {
        const socket = io(process.env.REACT_APP_SOCKET_URL, {
            query: {
                session: session.current
            }
        });
        setSocketIo(socket);
    }, []);

    return (
        <>
            <div className='container mx-auto max-w-xl h-full'>

                {loading &&
                    <div className='flex flex-col justify-center items-center h-[calc(100dvh)]'>
                        <Loader size="lg" content="กำลังตรวจสอบกล้อง" vertical />
                    </div>
                }

                {cameraBlocked || !cameraAvailable ?
                    <div className='flex flex-col justify-center items-center h-[calc(100dvh)]'>
                        <CameraIcon
                            width={100}
                            height={100}
                        />
                        <h4 className="mt-4 text-black">สิทธิ์การเข้าถึงกล้องถูกปฏิเสธ</h4>
                        <p className="">กรุณาอนุญาตให้เข้าถึงกล้อง</p>
                    </div>
                    : ''
                }

                {cameraAvailable && !cameraBlocked &&
                    <div className='flex flex-col justify-center items-center h-[calc(100dvh)]'>

                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            screenshotQuality={1}
                            forceScreenshotSourceSize={true}
                            videoConstraints={{
                                ...videoConstraints,
                                width: { ideal: 4096 },
                                height: { ideal: 2160 },
                                aspectRatio: 1.77
                            }}
                            width={1920}
                            height={1080}
                            className='rounded-xl aspect-video w-full h-auto object-cover'
                        />

                        <Button
                            className='mt-4'
                            appearance="primary"
                            onClick={capture}
                            loading={processingImage}
                            disabled={processingImage}
                        >
                            ถ่ายภาพ
                        </Button>
                    </div>
                }
            </div>

            <Drawer size='full' open={openWithHeader} onClose={handleClose} closeButton={false}>
                <div className='flex items-center p-4 border-b'>
                    <Button onClick={handleClose} appearance="subtle">
                        <ArowBackIcon />
                    </Button>
                    <h2 className=' font-medium text-lg'>กลับไปที่กล้อง</h2>
                </div>

                <div className='container mx-auto max-w-xl h-full py-4 px-2'>
                    {imageSrc && (
                        <div className='flex flex-col justify-center items-center'>
                            <h3 className='text-center mb-4 text-xl text-black'>ตรวจสอบภาพถ่ายของคุณ</h3>
                            <img src={imageSrc} alt='your doc' className='rounded-xl' />
                            <div className='flex gap-4 mt-4'>
                                <Button
                                    appearance="ghost"
                                    onClick={handleClose}
                                >
                                    กลับไปถ่ายรูปใหม่
                                </Button>
                                <Button
                                    appearance="primary"
                                    onClick={submitPhoto}
                                    loading={sendding}
                                    disabled={sendding}
                                >
                                    แนบภาพถ่าย
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Drawer>
        </>
    );
};

export default CameraCaptureMobile;