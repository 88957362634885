import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import { Button } from 'rsuite';

const Thanks = () => {

    const closeCurrentTab = () => {
        window.location.href = 'about:blank';
    }


    return (
        <div className='flex flex-col justify-center items-center h-[calc(100dvh)]'>
            <CheckOutlineIcon style={{ fontSize: 100, color: 'green' }} />
            <h1 className='text-4xl mt-4 mb-2'>ถ่ายรูปสำเร็จ</h1>
            <p className='mb-4'>ระบบได้แนบรูปถ่ายเอกสารลงใบสมัครเรียบร้อยแล้ว</p>
            <Button appearance="primary" onClick={closeCurrentTab}>ปิดหน้านี้</Button>
        </div>
    );
}

export default Thanks;