function CameraIcon({ width, height }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            enableBackground="new 0 0 512 512"
            viewBox="0 0 180 180"
        >
            <linearGradient
                id="a"
                x1="90"
                x2="90"
                y2="180"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#fbb222"></stop>
                <stop offset="1" stopColor="#ff6f37"></stop>
            </linearGradient>
            <g data-name="Layer 2">
                <g data-name="036.camera">
                    <rect
                        width="180"
                        height="180"
                        fill="url(#a)"
                        data-original="url(#a)"
                        rx="40.66"
                    ></rect>
                    <g fill="#fff">
                        <path
                            d="M53.16 130.08a13.1 13.1 0 01-9.4-3.84 13.27 13.27 0 01-3.76-9.57c.12-9.94.09-20 .07-29.83V74.27c0-7.64 5.33-13 12.94-13H68.7a.43.43 0 000-.07c.8-1.46 1.69-2.86 2.54-4.22l.19-.29c2.83-4.48 6.82-6.76 11.86-6.76h13.69c4.84 0 8.72 2.27 11.52 6.61 1 1.48 1.92 3.07 2.85 4.72h15.76c7.54 0 12.81 5.31 12.81 12.84v42.81c0 7.83-5.29 13.08-13.14 13.09H68.45c-5.84 0-10.63 0-15.07.1zm57.84-9.36h15.8c2.7 0 3.85-1.14 3.85-3.81V74.62c0-3.11-1-4.05-4.1-4.05H108.5a3.89 3.89 0 01-3.7-2.12c-1.64-2.84-3-5.12-4.37-7.19a4 4 0 00-3.52-2H83.07a4 4 0 00-3.57 2.12c-1.26 2-2.91 4.65-4.45 7.32a3.62 3.62 0 01-3.4 1.9H53.19c-2.77 0-3.84 1.07-3.84 3.8v42.42c0 2.79 1.17 3.93 4 3.93H111z"
                            data-original="#ffffff"
                        ></path>
                        <path
                            d="M90.11 116.87a21.23 21.23 0 1121.32-21.27 21 21 0 01-6.17 15 21.21 21.21 0 01-15.14 6.28zM90 83a12.67 12.67 0 000 25.33h.08a12.67 12.67 0 100-25.33zm-31.34 3a5.93 5.93 0 01-4.23-1.79 6.15 6.15 0 01-1.78-4.33 6.06 6.06 0 0112.11.1 6.11 6.11 0 01-6.09 6z"
                            data-original="#ffffff"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default CameraIcon;
